import { useToastAction } from '@metaforcelabs/metaforce-core';
import { useEffect, useState } from 'react';
import {
  getCompaniesSettingsApi,
  updateSettingsCompanyApprovals
} from '../../../api/companiesSettings';
import { Checkbox } from '../../../components/Form/Checkbox';
import Button from '../../../components/Button';

const Settings = () => {
  const [settings, setSettings] = useState({
    approvals: {
      activateCustomerAutomaticaly: false
    }
  });

  const getCompaniesSettingsAction = useToastAction();
  const updateSettingsCompanyApprovalsAction = useToastAction();

  const getCompaniesSettings = () => {
    getCompaniesSettingsAction.execute(async () => {
      const result = await getCompaniesSettingsApi();
      setSettings(result);
    }, 'Failed to load');
  };

  const update = () => {
    updateSettingsCompanyApprovalsAction.execute(async () => {
      await updateSettingsCompanyApprovals({
        activateCustomerAutomaticaly: settings.approvals.activateCustomerAutomaticaly
      });
    }, 'Failed to update');
  };

  useEffect(() => {
    getCompaniesSettings();
  }, []);

  return (
    <>
      <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9 mt-5">
        <div className="shadow sm:rounded-md sm:overflow-hidden">
          <div className="bg-white py-6 px-4 sm:p-6">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">Companies settings</h3>
            </div>

            <div className="flex mt-6">
              <div className="">
                <Checkbox
                  name="setting"
                  label="Active status by default"
                  checked={settings.approvals.activateCustomerAutomaticaly}
                  onChange={(checked) => {
                    setSettings({
                      ...settings,
                      approvals: {
                        ...settings.approvals,
                        activateCustomerAutomaticaly: checked
                      }
                    });
                  }}
                />
              </div>

              <div className="ml-auto">
                <Button variant={Button.variants.primary} onClick={update}>
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
