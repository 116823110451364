import { TextInput } from '../../../../components/Form/TextInput';
import React, { useEffect, useState } from 'react';
import { useClassNames } from '../../../../hooks/useClassNames';
import styles from './PackagesList.module.scss';
import Button from '../../../../components/Button';

export default function PackagesList({
  packagesListData,
  onSelectPackage,
  handleCreateNewPackage,
  selectedItem
}) {
  const [searchPackageValue, setSearchPackageValue] = useState('');

  const { classNames } = useClassNames();

  return (
    <>
      <div className="px-5 grid grid-cols-4">
        <div className="col-span-3 my-auto">
          <p className="text-lg font-medium leading-6 text-gray-900">Packages</p>
        </div>
        <div className="ml-auto">
          <Button variant={Button.variants.primary} onClick={handleCreateNewPackage}>
            Add
          </Button>
        </div>
      </div>

      <div className="mt-5 px-5">
        <TextInput
          name="searchCustomer"
          placeholder="Search package..."
          value={searchPackageValue}
          onChange={(value) => setSearchPackageValue(value)}
        />
      </div>

      <div className={classNames(packagesListData.length > 10 && 'overflow-y-auto')}>
        <ul
          className={classNames('mt-4 px-5 divide-y divide-gray-200 overflow-y-auto', styles.list)}
        >
          {packagesListData
            .filter((c) => {
              return searchPackageValue.length
                ? c.name.toLowerCase().includes(searchPackageValue.toLowerCase())
                : true;
            })
            .filter((c, index) => (index > 50 ? false : true))
            .map((c, index) => (
              <li
                key={c.packageId}
                className={classNames(
                  'px-4 py-4 cursor-pointer hover:bg-gray-100 hover:text-gray-900',
                  index % 2 && 'bg-gray-50',
                  selectedItem &&
                  selectedItem === c.packageId &&
                    'bg-indigo-100'
                )}
                onClick={() => onSelectPackage(c)}
              >
                <div className="flex items-center space-x-4">
                  <p className="truncate text-sm font-medium text-gray-900">{c.name}</p>
                </div>
              </li>
            ))}
        </ul>
      </div>
    </>
  );
}
