import FormikInput from '../../../../components/Form/Formik/FormikInput';
import { Select } from '../../../../components/Form/Select';
import DateField from '../../../../components/DateField';
import FormikErrorMessage from '../../../../components/Form/Formik/FormikErrorMessage';
import { FormikCheckbox } from '../../../../components/Form/Formik/FormikCheckbox';
import {
  ApplicationTypeCenterpointAlingment,
  ApplicationTypeInteracttAlingment,
  ApplicationTypes,
  PackageStates,
  PackageTypes
} from '../consts/VersionConstants';
import FormikTextArea from '../../../../components/Form/Formik/FormikTextArea';

export default function PackageGeneralInfo({ formikProps }) {
  const setChangelogIncludedApps = (category) => {
    if (category === '0') {
      ApplicationTypeCenterpointAlingment.forEach((x) => {
        formikProps.setFieldValue('changelog.' + x.value + '.included', true);
      });
      ApplicationTypeInteracttAlingment.forEach((x) => {
        formikProps.setFieldValue('changelog.' + x.value + '.included', false);
      });
    } else if (category === '1') {
      ApplicationTypeCenterpointAlingment.forEach((x) => {
        formikProps.setFieldValue('changelog.' + x.value + '.included', false);
      });
      ApplicationTypeInteracttAlingment.forEach((x) => {
        formikProps.setFieldValue('changelog.' + x.value + '.included', true);
      });
    }
  };

  const includedAppTypes = () => {
    return ApplicationTypes.sort((a, b) => a.name.localeCompare(b.name)).filter(
      (p) => formikProps.values.changelog[p.value].included
    );
  };

  return (
    <>
      <div className="mt-10 ml-3 pb-10">
        <div className="grid lg:grid-cols-5">
          <div className="my-auto">
            <label className="text-base font-medium text-gray-900">Package type</label>
          </div>
          <div className="mt-2">
            <Select
              name="packageType"
              options={PackageTypes}
              selectedValue={formikProps.values.packageType}
              onChange={(v) => {
                formikProps.setFieldValue('packageType', v);
                setChangelogIncludedApps(v);
              }}
            />
          </div>
        </div>
        <div className="grid lg:grid-cols-5 mt-5">
          <div className="my-auto">
            <label className="text-base font-medium text-gray-900">Package date</label>
          </div>
          <div className="mb-3">
            <DateField
              navbar={true}
              dataCy="packageDate"
              name="packageDate"
              value={formikProps.values.packageDate}
              onChange={(v, e) => {
                formikProps.setFieldValue('packageDate', v);
              }}
            />
            <FormikErrorMessage name={'packageDate'} />
          </div>
        </div>
        <div className="grid lg:grid-cols-5 mt-5">
          <div className="my-auto">
            <label className="text-base font-medium text-gray-900">Package version</label>
          </div>
          <div>
            <FormikInput name={'packageVersion'} formikProps={formikProps} />
          </div>
        </div>
        <div className="grid lg:grid-cols-5 mt-5">
          <div className="my-auto">
            <label className="text-base font-medium text-gray-900">State</label>
          </div>
          <div>
            <Select
              name="packageState"
              options={PackageStates}
              selectedValue={formikProps.values.packageState}
              onChange={(v) => {
                formikProps.setFieldValue('packageState', v);
              }}
            />
          </div>
        </div>
        <div className="grid lg:grid-cols-5 mt-5">
          <div className="mt-1">
            <label className="text-base font-medium text-gray-900">General info</label>
          </div>
          <div className="col-span-3 ">
            <FormikTextArea
              name="packageGeneralInfo"
              rows={3}
              value={formikProps.values.packageGeneralInfo}
              maxLength="255"
            />
          </div>
        </div>
        <div className="grid lg:grid-cols-5 mt-5">
          <div className="my-auto">
            <label className="text-base font-medium text-gray-900">Dependencies</label>
          </div>
          <div className="col-span-3">
            <FormikInput name={'packageDependencies'} formikProps={formikProps} />
          </div>
        </div>
        <div className="grid lg:grid-cols-5 mt-5 mb-10">
          <div className="mt-1">
            <label className="text-base font-medium text-gray-900">Download info</label>
          </div>
          <div className="col-span-3">
            <FormikTextArea
              name="packageDownloadInfo"
              rows={3}
              value={formikProps.values.packageDownloadInfo}
              maxLength="255"
            />
          </div>
        </div>
        <div className="grid lg:grid-cols-5 mt-5 mb-10">
          <div className="mt-8">
            <label className="text-base font-medium text-gray-900">Application Released</label>
          </div>
          <div className="col-span-4 grid lg:grid-cols-3 mt-5 mb-10">
            {includedAppTypes().map((p) => (
              <div key={'isReleasing_' + p.name} className="p-3">
                <FormikCheckbox
                  label={p.name}
                  name={'changelog.' + p.value + '.isReleasing'}
                  formikProps={formikProps}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
