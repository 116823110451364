import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import FormikInput from '../../../../components/Form/Formik/FormikInput';
import { FormikCheckbox } from '../../../../components/Form/Formik/FormikCheckbox';
import { FormikSubmitButton } from '../../../../components/Form/Formik/FormikSubmit';
import { DateTimeParser, GenericModal, useToastAction } from '@metaforcelabs/metaforce-core';
import { GetNotificationPreview, SendNotification } from '../../../../api/releasePackages';
import FormikTextArea from '../../../../components/Form/Formik/FormikTextArea';
import { Button } from '../../../../components';

export default function PackageNotification({ packageData, elementFormikProps }) {
  const initialFormValues = {
    notificationSubject: packageData.PackageLastSentNotificationSubject ? packageData.PackageLastSentNotificationSubject : '',
    notificationContent: packageData.PackageLastSentNotificationContent ? packageData.PackageLastSentNotificationContent : '',
    notificationOptions: [
      'attachReleasePackageInformation'
    ]
  };
  const [formValues, setFormValues] = useState(initialFormValues);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const [sentDate, setSentDate] = useState(packageData.packageNotificationSentDate);
  const postSendNotificationAction = useToastAction();
  const getNotificaitonPreviewAction = useToastAction();
  const [confirmDeleteCardModalOpen, setConfirmDeleteCardModalOpen] = useState(false);
  const [notificationPreview, setNotificationPreview] = useState('');
  const packageDataIsPublished = packageData.packageState.toString() === '1';

  const handleOnSubmit = (values, actions) => {
    postSendNotificationAction.execute(
      async () => {
        const request = {
          packageId: packageData.packageId,
          subject: values.notificationSubject,
          content: values.notificationContent,
          attachReleasePackageInformation:
            values.notificationOptions.includes('attachReleasePackageInformation'),
            attachDetailedChangelogs:
            values.notificationOptions.includes('attachDetailedChangelogs')
        };
        const response = await SendNotification(request);
        if (!response.isSuccess && response.messages) {
          response.messages.forEach((message) => {
            console.error(message);
          });
        };
        setSentDate(response.sentDate);
        actions.setSubmitting(false);
      },
      'Failed to send notification',
      'Notification has been sent'
    );
  };

  const handleOnModalOpen = (props) => {
    getNotificaitonPreviewAction.execute(
      async () => {
        const request = {
          packageId: packageData.packageId,
          subject: props.values.notificationSubject,
          content: props.values.notificationContent,
          attachReleasePackageInformation:
            props.values.notificationOptions.includes('attachReleasePackageInformation'),
            attachDetailedChangelogs:
            props.values.notificationOptions.includes('attachDetailedChangelogs')
        };
        const response = await GetNotificationPreview(request);
        if (!response.isSuccess && response.messages) {
          response.messages.forEach((message) => {
            console.error(message);
          });
        };
        setConfirmDeleteCardModalOpen(true);
        setNotificationPreview(response);
      },
      'Failed to get notification preview'
    );
  };

  const validationSchema = Yup.object().shape({
    notificationSubject: Yup.string().required('Required'),
    notificationContent: Yup.string().required('Required')
  });

  useEffect(() => {
    setFormValues({
      notificationSubject: packageData.packageLastSentNotificationSubject ? packageData.packageLastSentNotificationSubject : '',
      notificationContent: packageData.packageLastSentNotificationContent ? packageData.packageLastSentNotificationContent : '',
      notificationOptions: [
        'attachReleasePackageInformation'
      ]
    });
    setSubmitButtonDisabled(packageDataIsPublished 
      && (packageData.packageNotificationSentDate === null) ? false : true);
    if (packageData.sentDate !== sentDate) setSentDate(packageData.packageNotificationSentDate);
  }, [packageData]);

  return (
    <>
      <Formik
        initialValues={formValues}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
        enableReinitialize={true}>
        {(props) => (
          <Form>
            <div className="mt-10 ml-3 mr-10 pb-10">
              <div className="grid lg:grid-cols-5 mt-5">
                <div>
                  <label className="text-base font-medium text-gray-900">Subject</label>
                </div>
                <div className="col-span-3">
                  <FormikInput 
                  name={'notificationSubject'} 
                  formikProps={props} 
                  onChange={(v) => {
                    elementFormikProps.setFieldValue('PackageLastSentNotificationSubject', v.target.value);
                    props.setFieldValue('notificationSubject', v.target.value);
                  }}
                  />
                </div>
              </div>
              <div className="grid lg:grid-cols-5 mt-5 mb-10">
                <div className="mt-1">
                  <label className="text-base font-medium text-gray-900">Content</label>
                </div>
                <div className="col-span-3">
                  <FormikTextArea
                    name="notificationContent"
                    rows={10}
                    onChange={(v) => {
                      elementFormikProps.setFieldValue('packageLastSentNotificationContent', v.target.value);
                      props.setFieldValue('notificationContent', v.target.value);

                    }}
                  />
                </div>
              </div>
              <div className="grid lg:grid-cols-5 mt-5 mb-10">
                <div className="my-auto">
                  <label className="text-base font-medium text-gray-900">Attachments</label>
                </div>
                <div className="col-span-3">
                  <FormikCheckbox
                    label="Include Release Package General Information"
                    name="notificationOptions"
                    formikProps={props}
                    value={'attachReleasePackageInformation'}
                  />
                </div>
                <div className="col-start-2 col-span-3">
                  <FormikCheckbox
                    label="Include Detailed Applications Changelog"
                    name="notificationOptions"
                    formikProps={props}
                    value={'attachDetailedChangelogs'}
                  />
                </div>
              </div>
              <div className="grid lg:grid-cols-5 mt-5 mb-10">
                <div className="my-auto">
                  <label className="text-base font-medium text-gray-900">Status</label>
                </div>
                {sentDate == null && (
                  <div className="col-span-3 text-red-500">Notification hasn't been send yet</div>
                )}
                {sentDate !== null && (
                  <div className="col-span-3 text-green-500">
                    Notification has been 
                    sent on {DateTimeParser.toLocaleDateString(sentDate, true)}
                  </div>
                )}
              </div>
              <div className="grid lg:grid-cols-5 mt-5 mb-10">
                <div className="my-auto col-span-3 text-red-500">
                  {!packageDataIsPublished && packageData.packageNotificationSentDate === null && (
                    <p>
                      Notifications cannot be send if Release Package Version information is not
                      published.
                    </p>
                  )}
                </div>
                <div className="col-span-1 ml-auto flex gap-4">
                  <div>
                    <Button
                        variant={Button.variants.secondary}
                        onClick={e => handleOnModalOpen(props)}
                        disabled={!packageData?.packageId}>
                        Preview
                    </Button>
                  </div>
                  <FormikSubmitButton
                    id="send-notification"
                    text="Submit"
                    formikProps={props}
                    disabled={submitButtonDisabled}
                  />
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <GenericModal
            title={'Notification e-mail content preview'}
            setOpen={setConfirmDeleteCardModalOpen}
            open={confirmDeleteCardModalOpen}
            confirmButtonText={'Close'}
            showCancelButton={false}
            onConfirm={() => {setConfirmDeleteCardModalOpen(false)}}
            onCancel={() => {setConfirmDeleteCardModalOpen(false)}}>
            <div className="py-6">
                <div className="ml-4">
                    <div className="text-sm text-gray-600 text-left pb-10 pt-5 px-5 bg-gray-100">
                      <div dangerouslySetInnerHTML={{ __html: notificationPreview }}></div>
                    </div>
                </div>
            </div>
      </GenericModal>
    </>
  );
}
