export const initTabs = [
  {
    name: 'Package',
    current: true
  },
  {
    name: 'Details',
    current: false
  },
  {
    name: 'Customers',
    current: false
  },
  {
    name: 'Notification',
    current: false
  }
];

export const PackageTypes = [
  { name: 'CenterPoint', value: 0 },
  { name: 'Interact', value: 1}
];

export const PackageStates = [
  { name: 'Under Construction', value: 0 },
  { name: 'Published', value: 1 },
  { name: 'Deleted', value: 2 }
];

export const ApplicationTypes = [
  { name: 'Dynamo', value: 'dynamo' },
  { name: 'Digital Signing', value: 'digitalsigning' },
  { name: 'MFDX', value: 'mfdx' },
  { name: 'Metawrite', value: 'metawrite' },
  { name: 'Meta Tool', value: 'metatool' },
  { name: 'ViewPoint', value: 'viewpoint' },
  { name: 'Document Engine', value: 'documentengine' },
  { name: 'Smartforms', value: 'smartforms' },
  { name: 'Workflow', value: 'workflow' },
  { name: 'Support', value: 'support' },
  { name: 'Web Editor', value: 'webeditor' },
  { name: 'Text Library', value: 'textlibrary' },
  { name: 'Audit', value: 'audit' },
  { name: 'Archive', value: 'archive' },
  { name: 'CenterPoint Admin', value: 'centerpointadmin' },
  { name: 'Dialog ', value: 'dialog' },
  { name: 'Interact Test', value: 'interacttest' },
  { name: 'Interact Web Service (SOAP)', value: 'interactwebservicesoap' },
  { name: 'DiffTool', value: 'difftool' },
  { name: 'PPA', value: 'ppa' },
  { name: 'MFSolution', value: 'mfsolution' }
];

export const ApplicationTypeCenterpointAlingment = [
  { name: 'Audit', value: 'audit' },
  { name: 'Archive', value: 'archive' },
  { name: 'CenterPoint Admin', value: 'centerpointadmin' },
  { name: 'Dialog', value: 'dialog' },
  { name: 'Digital Signing', value: 'digitalsigning' },
  { name: 'Smartforms', value: 'smartforms' },
  { name: 'Support', value: 'support' },
  { name: 'Text Library', value: 'textlibrary' },
  { name: 'Web Editor', value: 'webeditor' },
  { name: 'Workflow', value: 'workflow' }
];

export const ApplicationTypeInteracttAlingment = [
  { name: 'Dynamo', value: 'dynamo' },
  { name: 'MetaTool', value: 'metatool' },
  { name: 'Document Engine', value: 'documentengine' },
  { name: 'MFDX', value: 'mfdx' },
  { name: 'Metawrite', value: 'metawrite' },
  { name: 'ViewPoint', value: 'viewpoint' },
  { name: 'Interact Test', value: 'interacttest' },
  { name: 'Interact Web Service (SOAP)', value: 'interactwebservicesoap' },
  { name: 'DiffTool', value: 'difftool' },
  { name: 'PPA', value: 'ppa' },
  { name: 'MFSolution', value: 'mfsolution' }
];

export const initialPackageDetails = {
  packageId: null,
  packageDate: '06/01/2023',
  packageType: 'centerpoint',
  packageVersion: '',
  packageState: 'draft',
  packageGeneralInfo: '',
  packageNotificationSentDate: null,
  packageDependencies: '',
  packageDownloadInfo: '',
  notificationRecipients: 'all',
  sentToSpecifiedCustomersList: [],
  changelog: {
    audit: {
      name: 'Audit',
      headline: '',
      dependencies: '',
      image: '',
      changelogNotes: '',
      version: '',
      isReleasing: false,
      included: true
    },
    archive: {
      name: 'Archive',
      headline: '',
      dependencies: '',
      image: '',
      changelogNotes: '',
      version: '',
      isReleasing: false,
      included: true
    },
    centerpointadmin: {
      name: 'CenterPoint Admin',
      headline: '',
      dependencies: '',
      image: '',
      changelogNotes: '',
      version: '',
      isReleasing: false,
      included: true
    },
    dialog: {
      name: 'Dialog',
      headline: '',
      dependencies: '',
      image: '',
      changelogNotes: '',
      version: '',
      isReleasing: false,
      included: true
    },
    digitalsigning: {
      name: 'Digital Signing',
      headline: '',
      dependencies: '',
      image: '',
      changelogNotes: '',
      version: '',
      isReleasing: false,
      included: true
    },
    documentengine: {
      name: 'Document Engine',
      headline: '',
      dependencies: '',
      image: '',
      changelogNotes: '',
      version: '',
      isReleasing: false,
      included: false
    },
    dynamo: {
      name: 'Dynamo',
      headline: '',
      dependencies: '',
      image: '',
      changelogNotes: '',
      version: '',
      isReleasing: false,
      included: false
    },
    metawrite: {
      name: 'Metawrite',
      headline: '',
      dependencies: '',
      image: '',
      changelogNotes: '',
      version: '',
      isReleasing: false,
      included: false
    },
    metatool: {
      name: 'Meta Tool',
      headline: '',
      dependencies: '',
      image: '',
      changelogNotes: '',
      version: '',
      isReleasing: false,
      included: false
    },
    mfdx: {
      name: 'MFDX',
      headline: '',
      dependencies: '',
      image: '',
      changelogNotes: '',
      version: '',
      isReleasing: false,
      included: false
    },
    smartforms: {
      name: 'Smartforms',
      headline: '',
      dependencies: '',
      image: '',
      changelogNotes: '',
      version: '',
      isReleasing: false,
      included: true
    },
    support: {
      name: 'Support',
      headline: '',
      dependencies: '',
      image: '',
      changelogNotes: '',
      version: '',
      isReleasing: false,
      included: true
    },
    textlibrary: {
      name: 'Text Library',
      headline: '',
      dependencies: '',
      image: '',
      changelogNotes: '',
      version: '',
      isReleasing: false,
      included: true
    },
    viewpoint: {
      name: 'ViewPoint',
      headline: '',
      dependencies: '',
      image: '',
      changelogNotes: '',
      version: '',
      isReleasing: false,
      included: false
    },
    webeditor: {
      name: 'Web Editor',
      headline: '',
      dependencies: '',
      image: '',
      changelogNotes: '',
      version: '',
      isReleasing: false,
      included: true,
      subModules: [
        {
          name: 'Web Editor API',
          key: 'webeditorapi',
          image: '',
          version: ''
        },
        {
          name: 'Web Editor NPM',
          key: 'webeditornpm',
          image: '',
          version: ''
        }
      ]
    },
    workflow: {
      name: 'Workflow',
      headline: '',
      dependencies: '',
      image: '',
      changelogNotes: '',
      version: '',
      isReleasing: false,
      included: true
    },
    interacttest: {
      name: 'Interact Test',
      headline: '',
      dependencies: '',
      image: '',
      changelogNotes: '',
      version: '',
      isReleasing: false,
      included: true
    },
    interactwebservicesoap: {
      name: 'Interact Web Service (SOAP)',
      headline: '',
      dependencies: '',
      image: '',
      changelogNotes: '',
      version: '',
      isReleasing: false,
      included: false
    },
    difftool: {
      name: 'DiffTool',
      headline: '',
      dependencies: '',
      image: '',
      changelogNotes: '',
      version: '',
      isReleasing: false,
      included: false
    },
    ppa: {
      name: 'PPA',
      headline: '',
      dependencies: '',
      image: '',
      changelogNotes: '',
      version: '',
      isReleasing: false,
      included: false
    },
    mfsolution: {
      name: 'MF Solution',
      headline: '',
      dependencies: '',
      image: '',
      changelogNotes: '',
      version: '',
      isReleasing: false,
      included: false
    }
  }
};
